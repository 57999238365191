// WD你我api文件不会用svn分离文件防止冲突
import axios from '@/api/ajax/aixos'

// 公共
// 根据编号获取字典
export const queryDicInfoByCode = (params) =>
  axios.post('/common/queryDicInfoByCode', params)
// 获取字典列表
export const queryDicInfoList = (params) =>
  axios.post('/common/queryDicInfoList', params)
// 角色
// 角色列表
export const getRoleList = (params) =>
  axios.post('/role/getRoleListPage', params)
// 角色update
export const roleUpdate = (params) =>
  axios.post('/role/saveOrUpdateRoleInfo', params)
// 角色添加用户
export const batchSaveUserRole = (params) =>
  axios.post('/user/batchSaveUserRole', params)
// 权限树接口
export const getTreeFindRole = (params) =>
  axios.post('/menu/findRoleFunction', params)
// 按钮权限接口
export const getBtnFindRole = (params) =>
  axios.post('/menu/findRoleFunctionsById', params)
// 获取当前用户当前菜单的按钮权限
export const getUserMenuBtn = (params) =>
  axios.post('/user/getUserMenuBtnByCurrentUser', params)
// 保存菜单权限
export const getTreeupdate = (params) =>
  axios.post('/userBusiness/updateMenuBusiness', params)

// 保存按钮权限
export const getBtnStrUpdate = (params) =>
  axios.post('/userBusiness/updateBtnStr', params)

// 用户
// 用户列表
export const getUserList = (params) =>
  axios.post('/user/getUserListPage', params)
// 重置密码
export const resetPwd = (params) => axios.post('/user/resetPwd', params)
// 用户详情
export const getUserInfo = (params) => axios.post('/user/getUserInfo', params)
// 用户新增保存接口
export const getUpdateUserInfo = (params) =>
  axios.post('/user/saveOrUpdateUserInfo', params)
// 获取部门树
export const getDepartTree = (params) =>
  axios.post('/depart/getDepartTree', params)
//根据部门id获取岗位
export const getAllPositionByDeptId = (params) =>
  axios.post('/depart/getAllPositionByDeptId', params)

//测试aes接口
export const aaa = (params) => {
  return axios.post('/user/getUserSession', params)
}

// 菜单管理
// 下拉接口
export const queryMenuDropdown = (params) => {
  return axios.post('/menu/queryMenuDropdown', params)
}
// 菜单增删
export const getOrUpdateMenuInfo = (params) => {
  return axios.post('/menu/saveOrUpdateMenuInfo', params)
}
//补卡截至日期
//添加
export const addCardReplaceConfig = (params) => {
  return axios.post('/attendance/saveReissueEndDate', params)
}
//编辑
export const editCardReplaceConfig = (params) => {
  return axios.post('/attendance/updateReissueEndDate', params)
}
//删除
export const delCardReplaceConfig = (params) => {
  return axios.post('/attendance/deleteReissueEndDateById', params)
}
//节假日
//添加
export const addHoliday = (params) => {
  return axios.post('/holiday/saveHoliday', params)
}
//删除
export const delHoliday = (params) => {
  return axios.post('/holiday/deleteHoliday', params)
}
//详情
export const infoHoliday = (params) => {
  return axios.post('/holiday/queryHolidayDetailById', params)
}
//编辑
export const editHoliday = (params) => {
  return axios.post('/holiday/updateHoliday', params)
}

// 节假日分类
// 节假日分类列表
export const queryHolidayMainPageList = (params) => {
  return axios.post('/holiday/queryHolidayMainPageList', params)
}
//新增节假日分类
export const saveHolidayMain = (params) => {
  return axios.post('/holiday/saveHolidayMain', params)
}
// 删除节假日分类
export const deleteHolidayMain = (params) => {
  return axios.post('/holiday/deleteHolidayMain', params)
}
// 修改节假日分类
export const updateHolidayMain = (params) => {
  return axios.post('/holiday/updateHolidayMain', params)
}
//工作时间配置
//添加
export const addWorkTime = (params) => {
  return axios.post('/deptWorkTime/saveDeptWorkTime', params)
}
//删除
export const delWorkTime = (params) => {
  return axios.post('/deptWorkTime/deleteDeptWorkTime', params)
}
//编辑
export const editWorkTime = (params) => {
  return axios.post('/deptWorkTime/updateDeptWorkTime', params)
}
//证书管理
//列表
export const getCertificateList = (params) => {
  return axios.post('/certificateManagement/queryAllCertificateName', params)
}
//添加
export const addCertificate = (params) => {
  return axios.post('/certificateManagement/saveCertificate', params)
}
//编辑
export const editCertificate = (params) => {
  return axios.post('/certificateManagement/updateCertificate', params)
}
//删除
export const delCertificate = (params) => {
  return axios.post('/certificateManagement/deleteCertificate', params)
}
//车辆信息
//添加
export const addVehicleInfo = (params) => {
  return axios.post('/carManage/saveCarBasicInfo', params)
}
//详情
export const getVehicleInfo = (params) => {
  return axios.post('/carManage/queryCarBasicInfoDetail', params)
}
//编辑
export const editVehicleInfo = (params) => {
  return axios.post('/carManage/updateCarBasicInfo', params)
}
//删除
export const delVehicleInfo = (params) => {
  return axios.post('/carManage/deleteCarBasicInfo', params)
}
//房间登记
//添加
export const addCheckIn = (params) => {
  return axios.post('/dormitory/saveDormitoryCheckIn', params)
}
//编辑
export const editCheckIn = (params) => {
  return axios.post('/dormitory/updateDormitoryCheckIn', params)
}
//退房
//添加
export const addCheckOut = (params) => {
  return axios.post('/dormitory/saveDormitoryCheckOut', params)
}
//编辑
export const editCheckOut = (params) => {
  return axios.post('/dormitory/updateDormitoryCheckOut', params)
}
//调房
//添加
export const addChangeHouse = (params) => {
  return axios.post('/dormitory/saveDormitoryTransfer', params)
}
//编辑
export const editChangeHouse = (params) => {
  return axios.post('/dormitory/updateDormitoryTransfer', params)
}
//水电费
//添加
export const addCost = (params) => {
  return axios.post('/dormitory/saveDormitoryUtilitiesCost', params)
}
//编辑
export const editCost = (params) => {
  return axios.post('/dormitory/updateDormitoryUtilitiesCost', params)
}
//详情
export const costDetail = (params) => {
  return axios.post('/dormitory/queryDormitoryUtilitiesCostDetail', params)
}
//住宿费用明细
//详情
export const getAccommodationCostDetail = (params) => {
  return axios.post('/dormitory/queryDormitoryAccommodationCostDetail', params)
}
//编辑
export const editAccommodationCostDetail = (params) => {
  return axios.post('/dormitory/updateDormitoryAccommodationCost', params)
}

//同步
export const asyncDormitoryUtilitiesCost = (params) => {
  return axios.post('/dormitory/asyncDormitoryUtilitiesCost', params)
}
///certificateManagement/updateInspectionFlag
export const jianyan = (params) => {
  return axios.post('/certificateManagement/updateInspectionFlag', params)
}

//保存访问记录
export const saveTraceAccessRecord = (params) => {
  return axios.post('/access/saveMenuVisit', params)
}

//查询用户查用菜单
export const queryFrequentlyUseMenu = (params) => {
  return axios.post('/access/queryFrequentlyUseMenu', params)
}

// 事项
// 我的事项
export const myQuery = (params) => {
  return axios.post(`/myItem/myApprovalProcessQuery`, params)
}

// 宿舍入住详情
export const queryDormitoryCheckInDetail = (params) => {
  return axios.post(`/dormitory/queryDormitoryCheckInDetail`, params)
}

export const queryDormitoryCheckOutDetail = (params) => {
  return axios.post(`/dormitory/queryDormitoryCheckOutDetail`, params)
}

export const queryDormitoryTransferDetail = (params) => {
  return axios.post(`/dormitory/queryDormitoryTransferDetail`, params)
}

// 查询上个月宿舍水电费用
export const queryLastDormitoryUtilitiesCost = (params) => {
  return axios.post(`/dormitory/queryLastDormitoryUtilitiesCost`, params)
}

// 获取角色列表
export const getRoleListAll = (params) => {
  return axios.post(`/role/getRoleList`, params)
}
