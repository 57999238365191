<template>
  <div class="dialog">
    <el-dialog
      v-dialogDrag
      :title="diaTitle"
      :visible.sync="dialogVisible"
      :width="diaWidth"
      :append-to-body="appendToBody"
      :close-on-click-modal="false"
    >
      <slot />
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input', false)" size="mini">取 消</el-button>
        <el-button
          v-if="diaString === 'form'"
          type="primary"
          @click="$emit('onSubmit', false)"
          size="mini"
          >保存</el-button
        >
        <el-button
          v-else
          type="primary"
          @click="$emit('input', false)"
          size="mini"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'myDialog',
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    diaString: {
      type: String,
      default: '',
    },
    diaTitle: {
      type: String,
      default: '默认title',
    },
    diaWidth: {
      type: String,
      default: '40%',
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
  computed: {},
  watch: {
    value(val) {
      this.dialogVisible = val
    },
    dialogVisible(val) {
      if (!val) {
        this.$emit('input', false)
      }
    },
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.dialog {
  margin: 0px;
  // height: 100%;
}
</style>
