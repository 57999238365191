<template>
  <div>
    <el-button
      type="primary"
      size="mini"
      style="margin: 0 10px"
      @click="openDialogPhoto()"
      >拍照生成PDF</el-button
    >
    <!-- <el-dialog
      title="拍照生成PDF"
      :visible.sync="dialogVisible"
      width="80%"
      top="30px"
      class="dialogHeight"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-row>
        <el-col :span="20" class="lf">
          <div class="grabimage_box">
            <img
              style="width: 100%;height: 100%;"
              id="view1"
              src="http://127.0.0.1:38088/video=stream&camidx=0"
              alt="主摄像头"
            />
          </div>
          <div class="imageList_box">
            <div class="imageList_box_all" v-if="imageList.length > 0">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
              <span>全选</span>
            </div>
            <el-checkbox-group v-model="checkImageList" @change="handleCheckedCitiesChange">
              <el-checkbox
                :label="item"
                v-for="(item, index) in imageList"
                :key="index"
              >
                <div class="image_box">
                  <el-image
                    style="width: 100px; height: 100px; border-radius: 5px"
                    :src="'data:image/*;base64,'+item"
                    fit="cover"
                  ></el-image>
                  <div class="delete_box">
                    <i
                      class="el-icon-delete delete_icon"
                      @click="deleteImage($event, index)"
                    ></i>
                  </div>
                </div>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col :span="4" class="rg">
          <div class="rg_box">
            <div class="rg_box_btn">
              <el-button type="primary" size="small" @click="getGrabimage" icon="el-icon-camera-solid"
                >拍照</el-button
              >
            </div>
            <div class="rg_box_btn">
              <el-button type="primary" size="small" @click="createPdf" icon="el-icon-s-order"
                >生成PDF</el-button
              >
            </div>
            <div class="rg_box_iframe">
              <iframe v-if="pdf" style="width: 100%" :src="'data:application/pdf;base64,'+pdf" frameborder="0" />
              <div v-if="pdf"  style="margin-top: 2px">注：PDF右键另存为本地文件</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" type="primary" @click="handleClose"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      uploadList: [],
      checkImageList: [],
      imageList: [
        // 'http://gips3.baidu.com/it/u=3886271102,3123389489&fm=3028&app=3028&f=JPEG&fmt=auto?w=1280&h=960',
        // 'http://gips2.baidu.com/it/u=1674525583,3037683813&fm=3028&app=3028&f=JPEG&fmt=auto?w=1024&h=1024',
        // 'http://gips0.baidu.com/it/u=1690853528,2506870245&fm=3028&app=3028&f=JPEG&fmt=auto?w=1024&h=1024',
        // 'http://gips2.baidu.com/it/u=195724436,3554684702&fm=3028&app=3028&f=JPEG&fmt=auto?w=1280&h=960',
        // 'http://gips0.baidu.com/it/u=3602773692,1512483864&fm=3028&app=3028&f=JPEG&fmt=auto?w=960&h=1280',
        // 'http://gips3.baidu.com/it/u=100751361,1567855012&fm=3028&app=3028&f=JPEG&fmt=auto?w=960&h=1280',
        // 'http://gips3.baidu.com/it/u=4261102772,1669669862&fm=3028&app=3028&f=JPEG&fmt=auto?w=1280&h=720',
        // 'http://gips0.baidu.com/it/u=3560029307,576412274&fm=3028&app=3028&f=JPEG&fmt=auto?w=960&h=1280',
        // 'https://gips3.baidu.com/it/u=4091827541,3279273508&fm=3028&app=3028&f=JPEG&fmt=auto&q=100&size=f1000_1000',
        // 'http://gips2.baidu.com/it/u=828570294,3060139577&fm=3028&app=3028&f=JPEG&fmt=auto?w=1024&h=1024',
        // 'http://gips3.baidu.com/it/u=617385017,3644165978&fm=3028&app=3028&f=JPEG&fmt=auto?w=1280&h=960',
        // 'http://gips1.baidu.com/it/u=2858031005,60232844&fm=3028&app=3028&f=JPEG&fmt=auto?w=1024&h=1024',
        // 'https://gips0.baidu.com/it/u=567323913,331130417&fm=3028&app=3028&f=JPEG&fmt=auto&q=100&size=f1000_1000',
        // 'https://cdn.esinna.cn/cgp/oa/test/tmpUpload/20241115110057209-oa-web.pdf',
      ],
      pdf: '',
      isIndeterminate: false,
      checkAll: false,
    }
  },
  props: {},
  methods: {
    handleCheckAllChange(val) {
      this.checkImageList = val ? this.imageList : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.imageList.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.imageList.length
    },
    openDialogPhoto() {
      // this.dialogVisible = true
      window.location.href = 'eloamCamera://'
    },
    deleteImage(e, index) {
      console.log(index, 9999)
      this.imageList.splice(index, 1)
      this.checkImageList.splice(index, 1)
      e.preventDefault()
    },
    // 拍照
    getGrabimage() {
      axios
        .post('http://127.0.0.1:38088/video=grabimage', { camidx: 0 })
        .then((res) => {
          this.imageList.push(res.data.photoBase64)
          console.log(this.imageList, 1111)
        })
        .catch(function (error) {
          this.$message.error('未连接上高拍仪！')
        })
    },
    // 生成pdf
    createPdf() {
      if (this.checkImageList.length > 0) {
        axios
          .post('http://127.0.0.1:38088/compose=photos', {
            outputfilepath: 'C://res.pdf', // 输出文件保存地址。例：D://res.jpg
            inputimagetype: '1', // 待合成文件类型：0：file；1：base64
            outputimagetype: '1', // 合成后文件类型：0：jpg；1：PDF
            imagelist: this.checkImageList.join(','),
          })
          .then((res) => {
            this.pdf = res.data.base64
          })
          .catch(function (error) {
            this.$message.error('未连接上高拍仪！')
          })
      } else {
        this.$message.error('请先勾选图片！')
      }
    },
    handleClose() {
      this.checkImageList = []
      this.imageList = []
      this.pdf = ''
      this.isIndeterminate = false
      this.checkAll = false
      this.dialogVisible = false
    },
  },
}
</script>
<style lang="scss">
.dialogHeight {
  height: 100vh;
  .el-dialog__header {
    height: 60px;
    border-bottom: 2px solid #eeeeee;
  }
  .el-dialog__body {
    height: calc(100vh - 200px);
    padding: 20px;
    .lf {
      .grabimage_box {
        height: calc(100vh - 400px);
        border: 2px solid #eeeeee;
      }
      .imageList_box {
        height: 160px;
        border: 2px solid #eeeeee;
        white-space: nowrap;
        overflow-x: auto;
        display: flex;
        align-items: center;
        padding: 0 20px 0 0;
        .imageList_box_all {
          display: flex;
          flex-direction: column;
          border-right: 1px solid #eeeeee;
          height: 100%;
          align-items: center;
          justify-content: center;
          width: 50px;
          margin-right: 20px;
        }
      }
    }
    .rg {
      height: calc(100vh - 240px);
      text-align: center;
      padding-top: 20px;
      border: 2px solid #eeeeee;
      padding: 20px 20px;
      .rg_box {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        .rg_box_btn {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          width: 100px;
        }
        .rg_box_iframe {
          min-height: 40%;
          max-height: 50%;
          margin-top: 100px;
          iframe {
            height: 100%;
          }
        }
      }
    }
  }
  .el-dialog__footer {
    height: 60px;
    border-top: 2px solid #eeeeee;
  }
}
.image_box {
  position: relative;
  .delete_box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    opacity: 0;
    background-color: #000;
    .delete_icon {
      position: absolute;
      left: 43px;
      top: 43px;
      color: #fff;
      z-index: 9999999;
      font-size: 16px;
    }
  }
  .delete_box:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
.isNoClick {
  pointer-events: none;
}
</style>
