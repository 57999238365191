<template>
  <div class="multipleSelect">
    <el-select
      v-model="value"
      placeholder=""
      multiple
      clearable
      collapse-tags
      popper-class="poppers"
      filterable
      ref="select"
      @clear="clear"
      @visible-change="visibleChange"
    >
      <div style="height: 180px; overflow: scroll">
        <el-checkbox-group
          v-model="checkedCities"
          @change="handleCheckedCitiesChange"
        >
          <div v-for="item in selectOptions" :key="item.id">
            <el-option
              :label="item[deployObj.label]"
              :value="item[deployObj.value]"
            >
              <el-checkbox :label="item[deployObj.value]" style="width: 100%">{{
                item[deployObj.label]
              }}</el-checkbox>
            </el-option>
          </div>
        </el-checkbox-group>
      </div>
      <div style="text-align: right; margin: 16px">
        <el-button size="mini" type="primary" @click="getSelect"
          >确认</el-button
        >
      </div>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'multipleSelect',
  components: {},
  props: {
    selectOptions: {
      type: Array,
    },
    deployObj: {
      type: Object,
      default: () => {
        return {
          value: 'id',
          label: 'name',
        }
      },
    },
    arr: {
      type: Array,
      default: () => [],
    },
    field: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checkedCities: [],
      value: '',
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {
    this.value = []
    this.checkedCities = []
    this.value.push(...this.arr)
    this.checkedCities.push(...this.arr)
  },
  methods: {
    handleCheckedCitiesChange() {
      this.value = this.checkedCities
    },
    clear() {
      this.value = []
      this.checkedCities = []
      this.$emit('visibleChange', [], this.field)
    },
    visibleChange(flag) {
      // if (!flag) this.$emit('visibleChange', this.checkedCities)
    },
    getSelect() {
      this.$refs.select.blur()
      this.$emit('visibleChange', this.checkedCities, this.field)
    },
  },
}
</script>

<style scoped lang="scss">
.multipleSelect {
  display: flex;
  align-items: center;
  height: 38px;
  .el-select {
    .el-select__tags {
      .el-tag.el-tag--info:nth-child(1) {
        width: 80px;
      }
      .el-tag.el-tag--info:nth-child(2) {
        width: 40px;
      }
    }
  }
  // height: 40px !important;
}
.poppers {
  margin: 0 !important;
}
.popper__arrow {
  display: none !important;
}
.el-tag__close {
  display: none !important;
}
</style>
