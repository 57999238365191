<template>
  <div class="baseCard">
    <el-card>
      <slot></slot>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'baseCard',
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.baseCard {
  height: 100%;
  overflow: scroll;
  ::v-deep .el-card__body {
    height: 100%;
    overflow: scroll;
    padding: 12px 12px 13px 12px;
  }
}
</style>
